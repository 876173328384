div.qempo-invoice-lines-table {
  table {
    border-collapse: collapse;
  }

  tr {
    &.top-border {
      border-top: solid 2px black;
    }

    &.bottom-border {
      border-bottom: solid 2px black;
    }
  }
}
