
div.welcomeWrap {
  background: #0186f7;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

div.welcomeWrap div.logoWrap {
  position: relative;
  z-index: 10;
}

div.welcomeWrap div.logoWrap img.logo {
  width: 250px;
  height: 71px;
  display: block;
}

div.welcomeWrap div.logoWrap a {
  color: white;
}

div.welcomeWrap img.world {
  max-width: 800px;
  min-width: 500px;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 1;
}
