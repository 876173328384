nav.ant-breadcrumb {
  margin-bottom: 8px;

  li {
    display: flex;
    align-items: center;
  }
}


div.ant-collapse {
  overflow: hidden;

  div.ant-collapse-item {
    &.success {
      &.light {
        div.ant-collapse-header {
          background: #f6ffed!important;
        }
      }

      &.dark {
        div.ant-collapse-header {
          background: #162312!important;
        }
      }
    }

    &.warning {
      &.light {
        div.ant-collapse-header {
          background: #fffbe6;
        }
      }
    }
  }
}

div.cm-theme {
  border-radius: 6px;
  border: 1px solid rgb(229, 231, 235);
  overflow: hidden;
}
