
div.order-ids-wrap {
  margin-bottom: 24px;
}

div.order-ids-wrap div.order-ids-tags-wrap {
  margin-bottom: 16px;
}

div.order-ids-wrap div.ant-form-item-label {
  padding: 0 0 8px 0!important;
}
